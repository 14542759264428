.content-preview {
    flex: 33.333%;
    margin-bottom: -4px;
    padding: 5px;
}

@media (max-width: 1200px) {
    .content-preview {
        flex: 50%;
    }
}

@media (max-width: 800px) {
    .content-preview {
        flex: 100%;
    }
}

.thumbnail {
    width: 100%;
    height: 100%;
    border: 1px solid white;
}

.content-preview h2 {
    position: absolute;
    font-size: 16pt;
    padding: 8px 18px;
    border: 1px solid white;
}