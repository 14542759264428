@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --font-color-light-mode: #4D4D4D;
  --font-color-dark-mode: #fdfdfd;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

#main-page-reel {
  position: fixed;
  z-index: -2;
  top: 0;
  margin-left: 50%; 
  transform: translate(-50%);
}

.dim-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.30);
}

@media (min-aspect-ratio: 16/9) {
  #main-page-reel {
      width:100%;
      height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  #main-page-reel { 
      width:auto;
      height: 100%;
  }
}

img.logo{
  width: 60px;
  left: 50%;
}

img.intro-logo {
  display: block;
  position: absolute;
  margin-left: 50%;
  margin-top: 200%;
}

.wrapper {
  text-align: center;
  padding: 80px 20px;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

h1 {
  color: #fdfdfd;
  color: var(--font-color-dark-mode);
  font-family: 'Poppins', sans-serif;
  font-size: 32pt;
  font-weight: 200;
}

h2 {
  color: #fdfdfd;
  color: var(--font-color-dark-mode);
  font-size: 20pt;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

p {
  font-size: 16pt;
  color: #fdfdfd;
  color: var(--font-color-dark-mode);
}

.aa-text {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 48pt;
}

ul.questionaire-questions {
  display: inline-block;

}

li.questionaire-answer {
  margin: 10px;
  list-style: none;
  display: inline-block;
}

li.questionaire-answer:hover {
  opacity: 75%;
}

.questionaire-icon {
  width: 80px;
}

.questionaire-answer {
  cursor: pointer;
}

#questionaire-container {
  border: 1px solid white;
  padding: 10px;
}

.home-page-logo {
  width: 200px;
}

.glass-effect {
  background-color: rgba(50, 50, 50, 0.5);
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
}
.nav_links li {
    list-style: none;
  
    display: inline-block;
    padding: 0px 15px; /* Space between the nav links. Seperate 0px (top/bottom) from 10px (right/left) with a space, NOT A COMMA*/
}
  
.nav_links li a {
    color: var(--font-color-dark-mode);
    font-family: 'Poppins', sans-serif;
    font-size: 16pt;
    font-weight: 500;
    text-decoration: none;
}

header {
}
.content-preview {
    flex: 33.333% 1;
    margin-bottom: -4px;
    padding: 5px;
}

@media (max-width: 1200px) {
    .content-preview {
        flex: 50% 1;
    }
}

@media (max-width: 800px) {
    .content-preview {
        flex: 100% 1;
    }
}

.thumbnail {
    width: 100%;
    height: 100%;
    border: 1px solid white;
}

.content-preview h2 {
    position: absolute;
    font-size: 16pt;
    padding: 8px 18px;
    border: 1px solid white;
}
.content-preview-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 5px;
}

/* @media (max-width: 800px) {
    .content-preview-container {
      flex-direction: column;
    }
} */

.video-spacer {
    opacity: 0;
    width: 100%;
}
body {
    background-color: rgb(88, 88, 88);
}
