.content-preview-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 5px;
}

/* @media (max-width: 800px) {
    .content-preview-container {
      flex-direction: column;
    }
} */

.video-spacer {
    opacity: 0;
    width: 100%;
}