.nav_links li {
    list-style: none;
  
    display: inline-block;
    padding: 0px 15px; /* Space between the nav links. Seperate 0px (top/bottom) from 10px (right/left) with a space, NOT A COMMA*/
}
  
.nav_links li a {
    color: var(--font-color-dark-mode);
    font-family: 'Poppins', sans-serif;
    font-size: 16pt;
    font-weight: 500;
    text-decoration: none;
}

header {
}