@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

:root {
  --font-color-light-mode: #4D4D4D;
  --font-color-dark-mode: #fdfdfd;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

#main-page-reel {
  position: fixed;
  z-index: -2;
  top: 0;
  margin-left: 50%; 
  transform: translate(-50%);
}

.dim-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.30);
}

@media (min-aspect-ratio: 16/9) {
  #main-page-reel {
      width:100%;
      height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  #main-page-reel { 
      width:auto;
      height: 100%;
  }
}

img.logo{
  width: 60px;
  left: 50%;
}

img.intro-logo {
  display: block;
  position: absolute;
  margin-left: 50%;
  margin-top: 200%;
}

.wrapper {
  text-align: center;
  padding: 80px 20px;
  margin: auto;
  width: fit-content;
}

h1 {
  color: var(--font-color-dark-mode);
  font-family: 'Poppins', sans-serif;
  font-size: 32pt;
  font-weight: 200;
}

h2 {
  color: var(--font-color-dark-mode);
  font-size: 20pt;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

p {
  font-size: 16pt;
  color: var(--font-color-dark-mode);
}

.aa-text {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 48pt;
}

ul.questionaire-questions {
  display: inline-block;

}

li.questionaire-answer {
  margin: 10px;
  list-style: none;
  display: inline-block;
}

li.questionaire-answer:hover {
  opacity: 75%;
}

.questionaire-icon {
  width: 80px;
}

.questionaire-answer {
  cursor: pointer;
}

#questionaire-container {
  border: 1px solid white;
  padding: 10px;
}

.home-page-logo {
  width: 200px;
}

.glass-effect {
  background-color: rgba(50, 50, 50, 0.5);
  backdrop-filter: blur(50px);
}